<template>
    <div class="main">
        <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        title="提现账号" />

        <div class="line"></div>

        <div class="item">
            <div class="a">姓名</div>
            <input class="b" v-model="realName" placeholder="请填写姓名">
        </div>

        <div class="subLine"></div>

        <div class="item">
            <div class="a">支付宝账号</div>
            <input class="b" v-model="account" placeholder="请填写支付宝账号">
        </div>

        <div class="line"></div>

        <div v-if="account.length > 0 && realName.length > 0" class="commit" @click="commit()">确认</div>
        <div v-else class="commit1">确认</div>

        <van-popup v-model="safeCheckDialogVisiable">
            <div class="selfCheckDialog">
                <div class="title">安全验证</div>
                <div class="mobile">{{ mobile }}</div>
                <div class="smsArea">
                    <input class="inputArea" v-model="code">
                    <div class="getCode" @click="sendSimCode()">{{ getCodeTip() }}</div>
                </div>
                <div class="btns">
                    <div class="cancelBtn" @click="safeCheckDialogVisiable = false">取消</div>
                    <div class="confirmBtn" @click="confirmCommit()">确认</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'
import { Toast } from 'vant'

export default {
    data() {
        return {
            realName: '',
            account: '',

            safeCheckDialogVisiable: false,
            mobile: '',
            code: '',
            countDown: 0,
            timer: null,
        }
    },
    methods: {
        getCodeTip() {
            if (this.countDown == 0) {
                return '获取验证码'
            } else {
                return this.countDown + ''
            }
        },
        sendSimCode() {
            if (this.countDown > 0) {
                this.safeCheckDialogVisiable = true
                return
            }
            doRequest('/sms/sendCode', {
                code_id: 4,
            }, this.$route.query).then(res => {
                this.mobile = res.mobile
                this.safeCheckDialogVisiable = true
                this.countDown = 59
                this.timer = setInterval(() => {
                    if (this.countDown == 0) {
                        clearInterval(this.timer)
                        return
                    }
                    this.countDown --
                }, 1000)
            })
        },
        commit() {
            if (this.realName.length == 0) {
                Toast.fail('请输入姓名')
                return
            }
            if (this.account.length == 0) {
                Toast.fail('请输入支付宝账号')
                return
            }
            this.sendSimCode()
        },
        confirmCommit() {
            doRequest('/withdraw/saveAccount', {
                account: this.account,
                real_name: this.realName,
                code: this.code,
            }, this.$route.query).then(() => {
                Toast.success('保存成功')
                this.safeCheckDialogVisiable = false
                this.amount = ''
                this.$router.go(-1)
            })
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        initData() {
            doRequest('/withdraw/getAccount', {}, this.$route.query).then(res => {
                this.realName = res.real_name
                this.account = res.account
            })
        },
    },
    mounted() {
        this.initData()
    },
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.line {
    width: 375px;
    height: 10px;
    background: #F4F4F4;
}

.subLine {
    width: 331px;
    height: 1px;
    background: #DDDDDD;
    border-radius: 0px;
    margin-left: 21px;
}

.item {
    width: 375px;
    height: 57px;
    display: flex;
    align-items: center;
}

.item .a {
    width: 85px;
    height: 15px;    
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 15px;
    margin-left: 25px;
}

.item .b {
    width: 200px;
    /* height: 14px; */
    /* vertical-align: top;
    margin:0px; padding:0px; */
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
}

.commit {
    width: 273px;
    height: 55px;
    background: linear-gradient(-90deg, #F53760 0%, #FB6F8D 100%);
    box-shadow: 0px 5px 8px 0px #FFEFCD;
    border-radius: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    margin-top: 120px;
    margin-left: 52px;
}

.commit1 {
    width: 273px;
    height: 55px;
    background: linear-gradient(-90deg, #CCCCCC 0%, #CCCCCC 100%);
    box-shadow: 0px 5px 8px 0px #CCCCCC;
    border-radius: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    margin-top: 120px;
    margin-left: 52px;
}

.selfCheckDialog {
    width: 318px;
    height: 260px;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.selfCheckDialog .title {
    height: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3B3B38;
    line-height: 20px;
    margin-left: 24px;
    margin-top: 20px;
}

.selfCheckDialog .mobile {
    height: 13px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 13px;
    margin-left: 24px;
    margin-top: 19px;
}

.selfCheckDialog .smsArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 270px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin-top: 25px;
    margin-left: 24px;
}

.selfCheckDialog .smsArea .inputArea {
    height: 30px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    margin-left: 20px;
}

.selfCheckDialog .smsArea .getCode {
    width: 100px;
    height: 15px;
    text-align: center;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 15px;
    border-left: 1px solid #999999;
}

.selfCheckDialog .btns {
    height: 45px;
    width: 269px;
    display: flex;
    justify-content: space-between;
    margin-top: 37px;
    margin-left: 26px;
}

.selfCheckDialog .btns .cancelBtn {
    width: 122px;
    height: 45px;
    border: 1px solid #F53760;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #F53760;
}

.selfCheckDialog .btns .confirmBtn {
    width: 122px;
    height: 45px;
    background: linear-gradient(-90deg, #F53760 0%, #FB6F8D 100%);
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

input {
    width: 150px;
    outline:none;
    background:transparent;
    border:none;
    outline:medium;
}
</style>